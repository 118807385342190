<template>
  <button
    :disabled="disabled"
    class="whitespace-nowrap text-sm leading-base font-medium rounded-x1 flex items-center focus:outline-none transition duration-150 ease-out"
    :class="{
      'border border-error': invalid,
      'bg-accent-color text-white':
        (accent && !active && !menu) || (!accent && !menu && active),
      'bg-menu text-font-primary': menu && !active && !accent,
      '!bg-primary-grey-background !text-font-primary':
        menu && active && !accent,
      'hover:bg-lite-accent-color focus:bg-lite-accent-color hover:text-accent-color':
        accent && !active && !menu,
      'hover:bg-primary-grey-background focus:bg-primary-grey-background hover:text-font-primary':
        !accent && !active && menu,
      'hover:border hover:border-accent-color hover:ease-in': !accent && !menu,
      'bg-lite-accent-color text-accent-color': accent && active && !menu,
      'bg-primary-grey-background text-font-primary':
        !accent && !active && !menu,
      [`min-w-[${minWidth}px]`]: minWidth,
      'min-w-max': !minWidth && widthMax,
      'min-w-[120px]': !minWidth && !widthMax,
      'w-full': expand,
      [`min-h-[${minHeight}px] h-[${minHeight}px]`]: minHeight,
      '!min-h-[40px] !h-[40px]': !minHeight,
      'pointer-events-none': disabled,
      border,
    }"
    @click="emit('click')"
  >
    <div class="flex items-center justify-center w-full empty:hidden">
      <slot name="icon"></slot>
      <template v-if="text">
        <div>
          {{ text }}
        </div>
      </template>
    </div>
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  disabled: { type: Boolean, default: false },
  text: { type: String, default: "" },
  expand: { type: Boolean, default: false },
  active: { type: Boolean, default: false },
  accent: { type: Boolean, default: false },
  menu: { type: Boolean, default: false },
  invalid: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: String,
  },
  minHeight: {
    type: String,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  widthMax: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);
</script>
